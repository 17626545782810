<template>
  <CFooter :fixed="false">
    <div>
      
      Master Suite
      <span class="ml-1">&copy; {{new Date().getFullYear()}}</span>
    </div>
    <div class="mfs-auto">
      <span class="mr-1" target="_blank">Powered by</span>
      <a href="https://www.valorartfauditores.com">Consorcio Valorar - TF Auditores</a>
    </div>
  </CFooter>
</template>

<script>
export default {
  name: 'TheFooter'
}
</script>
